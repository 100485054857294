<template>
  <!-- <v-container class="fill-height" fluid> -->
  <v-container fluid>
    <a href="https://mygewgaw.business.site/" target="_blank">
      <v-img src="@/assets/myGewgaw_1440x900.png" height="125px" contain>
      </v-img>
    </a>

    <p>
      <strong>gewgaw</strong> noun
      <br />
      gew·gaw | \ g(y)ü-(ˌ)gȯ
      <br />
      : a showy trifle : bauble, trinket
    </p>

    <p>
      myCollexion represents your collection of baubles, trinkets, bibelot,
      item, knickknack, memento, souvenir, toy, whatnot, conversation piece,
      curio, objet d'art
    </p>

    <p>
      We searched for ways to catalog our inventory and were discouraged by
      featureless applications, rudimentary interfaces, cumbersome spreadsheets,
      and outlandish prices. So we decided to roll our own and conceptualized
      our flagship product, myCollexion, as a minimal viable product.
    </p>

    <!-- https://www.productplan.com/glossary/minimum-viable-product/ -->
    <blockquote
      cite="https://www.productplan.com/glossary/minimum-viable-product/"
    >
      A minimum viable product, or MVP, is a product with enough features to
      attract early-adopter customers and validate a product idea early in the
      product development cycle. In industries such as software, the MVP can
      help the product team receive user feedback as quickly as possible to
      iterate and improve the product.
    </blockquote>

    <!-- <v-card class="col-xs-4 col-sm-5 col-md-3 col-lg-2 mx-4" flat>
      <a href="https://mygewgaw.business.site/" target="_blank">
        <v-img
          src="@/assets/qrcode_mygewgaw.business.site.png"
          height="200px"
          width="200px"
          link
          href="https://mygewgaw.business.site/"
          target="_blank"
          contain
        />
      </a>
      <v-card-text class="py-0">Scan or click</v-card-text>
    </v-card> -->

    <!-- <collexion-footer></collexion-footer> -->
  </v-container>
</template>

<script>
// import CollexionFooter from "@/components/footer";

export default {
  name: "App",
  components: {
    // CollexionFooter,
  },
  data: () => ({
    //
  }),
};
</script>

<style>
blockquote {
  font: 14px/22px normal helvetica, sans-serif;
  background: #e9e9e9;
  border-left: 10px solid #ccc;
  border-right: 10px solid #ccc;
  margin: 1.5em 20px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #aaa;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: #aaa;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
</style>
