<template>
  <v-app>
    <collexion-navigation></collexion-navigation>

    <!-- Sizes your content based upon application components -->
    <!-- <v-main style="padding: 48px 0px 0px 56px"> -->
    <v-main>
      <!-- <v-container class="fill-height" fluid> -->
      <v-container fluid>
        <!-- <v-row align="center" justify="center"> -->
        <v-row>
          <v-col class="pa-0">
            <router-view :key="$route.fullPath"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- <collexion-footer></collexion-footer> -->
  </v-app>
</template>

<script>
import CollexionNavigation from "@/components/navigation";
// import CollexionFooter from "@/components/footer";

export default {
  name: "App",
  components: {
    CollexionNavigation,
    // CollexionFooter,
  },
  // data: () => ({
  //   //
  // }),
  // computed: {
  //   getUsers() {
  //     return this.$store.getters.getters3;
  //   },
  // },
};
</script>

<style>
html {
  /* overflow-y: hidden !important; */
  overflow-y: auto !important;
}
/* #content {
  height: calc(100vh - 56px);
  overflow-y: auto;
} */
</style>

<style scoped>
/* .border {
  border-left: 4px solid #0ba518;
} */
/* a {
  color: white;
  text-decoration: none;
} */
/* .no-scroll {
  -ms-overflow-style: none; /* IE and Edge * /
  scrollbar-width: none; /* Firefox * /
} */
/* .no-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera * /
} */
/* ::v-deep ::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera * /
  width: 0;
  background: transparent;
}
/* TODO app.vue overflow-y: hidden somewhere */
/* html {
  overflow-y: hidden;
}
.v-application {
  overflow-y: hidden;
}
.v-navigation-drawer__content {
  overflow-y: hidden;
} */
</style>
