import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
  },
  // Breyer
  {
    path: "/molds",
    name: "molds",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "molds" */ "@/views/BreyerView.vue"),
  },
  {
    path: "/models",
    name: "models",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      // import(/* webpackChunkName: "molds" */ "@/views/TableView.vue"),
      import(/* webpackChunkName: "molds" */ "@/views/BreyerView.vue"),
  },
  // {
  //   path: "/image",
  //   name: "image",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "@/views/ImageView.vue")
  // }
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue")
  // },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/BreyerView.vue"),
  },
  {
    path: "/accessories",
    name: "accessories",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/BreyerView.vue"),
  },
  {
    path: "/catalog",
    name: "catalog",
    component: () =>
      import(
        /* webpackChunkName: "table" */ "@/components/UnderConstruction.vue"
      ),
  },
  {
    path: "/shows",
    name: "shows",
    component: () =>
      import(
        /* webpackChunkName: "table" */ "@/components/UnderConstruction.vue"
      ),
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () =>
      import(
        /* webpackChunkName: "table" */ "@/components/UnderConstruction.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "table" */ "@/components/UnderConstruction.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/BreyerView.vue"),
  },
  {
    path: "/iso",
    name: "iso",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/CatalogTable.vue"),
  },
  {
    path: "/price",
    name: "price",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/CatalogTable.vue"),
  },
  {
    path: "/categories",
    name: "categories",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/TableView.vue"),
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/TableView.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/TableView.vue"),
  },
  {
    path: "/orders",
    name: "orders",

    component: () =>
      import(/* webpackChunkName: "table" */ "@/views/TableView.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

// navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // TODO
  // const currentUser = firebase.auth().currentUser;
  const currentUser = true;

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

// TODO try $store instead of firebase
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((route) => route.meta.requiresAuth)) {
//     if (Vue.$store.state.Auth.is_auth_token) return next();

//     return next("/non-auth-required-route");
//   }

//   next();
// });

//添加以下代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
