export default {
  state: {
    packageName: process.env.PACKAGE_NAME,
    packageVersion: process.env.PACKAGE_VERSION || "0",
    packageDescription: process.env.PACKAGE_DESCRIPTION,
    packageAuthor: process.env.PACKAGE_AUTHOR,
    packageMode: process.env.NODE_ENV,
  },
  getters: {
    appName: (state) => {
      return state.packageName;
    },
    /// To fix this issue, replace the usage of navigator.userAgent, navigator.appVersion, and navigator.platform with feature detection, progressive enhancement, or migrate to navigator.userAgentData.
    appVersion: (state) => {
      return state.packageVersion;
    },
    appDescription: (state) => {
      return state.packageDescription;
    },
    appAuthor: (state) => {
      return state.packageAuthor;
    },
    appMode: (state) => {
      return state.packageMode;
    },
  },
};
