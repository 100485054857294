<template>
  <v-card class="no-scroll">
    <!-- Sidebar -->
    <v-navigation-drawer
      app
      dark
      mini-variant
      mini-variant-width="56"
      permanent
      expand-on-hover
      :height="bgHeight()"
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          class="justify-center"
          link
          @click="openMenu(item)"
        >
          <!-- <v-avatar size="32px" :color="item.avatarcolor"> -->
          <v-avatar size="32px">
            <v-img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
            <v-icon v-else :color="item.iconcolor" v-text="item.icon" />
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="px-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="mx-2" color="white"></v-divider>
      <!-- <v-divider></v-divider> -->

      <v-list dense nav>
        <v-list-item
          v-for="item in items2"
          :key="item.title"
          class="justify-center"
          link
          @click="openMenu(item)"
        >
          <v-avatar size="32px">
            <v-img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
            <v-icon v-else :color="item.color" v-text="item.icon" />
            <!-- <v-icon v-else :color="item.iconcolor" v-text="item.icon" /> -->
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="px-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Configuration -->
      <template v-slot:append>
        <v-list dense nav>
          <v-list-item
            v-for="item in items3"
            :key="item.title"
            class="justify-center"
            link
            @click="openMenu(item)"
          >
            <!-- <v-avatar size="32px">
              <v-img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
              <v-icon v-else :color="item.iconcolor" v-text="item.icon" />
            </v-avatar> -->
            <!-- <v-icon x-large color="primary">$vuetify.icons.config</v-icon> -->
            <!-- <v-icon color="primary">{{ ConfigIcon }}</v-icon> -->
            <!-- <v-icon icon="$config" /> -->
            <config-icon />
            <v-list-item-content>
              <v-list-item-title class="px-2">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <!-- Popout menu -->
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      @mouseleave.native.stop="drawer = !drawer"
    >
      <!-- @mouseleave="drawer = !drawer" -->
      <v-list dense>
        <!-- <v-list class="grow" dense> -->
        <!-- TODO use menu.color -->
        <!-- <v-list-item class="(menu.color)"> -->
        <v-list-item class="yellow darken-4 px-0">
          <v-avatar size="32px" class="mx-2">
            <v-img v-if="menu.avatar" alt="Avatar" :src="menu.avatar" />
            <v-icon v-else :color="menu.color" v-text="menu.icon" />
          </v-avatar>
          {{ menu.title }}
        </v-list-item>
        <v-divider></v-divider>

        <!-- add class="px-0" to left justify -->
        <!-- @click.native.stop="drawer = !drawer" -->
        <v-list-item
          v-for="route in menu.routes"
          :key="route.title"
          link
          :href="route.link"
          target="_blank"
          @click.native.stop="drawer = !drawer"
          @click="navigateTo(route.path)"
        >
          <v-avatar size="32px" :color="route.avatarcolor" class="mx-2">
            <v-img v-if="route.avatar" alt="Avatar" :src="route.avatar" />
            <v-icon v-else :color="route.color" v-text="route.icon" />
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="px-0">
              {{ route.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Title -->
    <!-- <v-app-bar app dense>
      <v-toolbar-title class="text-uppercase red--text">
        <span>{{ $store.getters.appName }}</span>
      </v-toolbar-title>
    </v-app-bar> -->

    <!-- Footer -->
    <collexion-footer></collexion-footer>
  </v-card>
</template>

<script>
import CollexionFooter from "@/components/footer";
import ConfigIcon from "@/icons/ConfigIcon.vue";

export default {
  name: "CollexionNavigation",
  data: () => ({
    drawer: null,
    hover: false,
    menu: [],
    routes: [],
    // TODO items[] need to populate from JSON API
    items: [
      // myCollection
      {
        title: "myCollexion",
        avatar: require("@/assets/myGewgawLogo_512x512.png"),
        avatarcolor: "pink",
        color: "green lighten-2",
        routes: [
          {
            title: "Catalog",
            path: "catalog",
            icon: "mdi-format-list-bulleted",
          },
          {
            title: "Molds",
            path: "molds",
            // avatar: "https://www.identifyyourbreyer.com/favicon.ico",
            icon: "mdi-horse",
          },
          { title: "Models", path: "models", icon: "mdi-horse-variant" },
          { title: "Shows", path: "shows", icon: "mdi-theater" },
          {
            title: "Transactions",
            path: "transactions",
            icon: "mdi-handshake-outline",
          },
          { title: "In Search Of", path: "iso", icon: "mdi-magnify" },
          { title: "Price Guide", path: "price", icon: "mdi-currency-usd" },
        ],
      },
      // Reference Sites
      {
        title: "Reference Sites",
        icon: "mdi-magnify",
        iconcolor: "white",
        routes: [
          {
            title: "Breyer Horses",
            avatar:
              "https://cdn.shopify.com/s/files/1/0015/8898/5892/files/Breyer_favicon_32x32_32x32.png",
            avatarcolor: "",
            link: "https://www.breyerhorses.com/",
            color: "",
          },
          {
            title: "IdentifyYourBreyer",
            avatar: "https://www.identifyyourbreyer.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.identifyyourbreyer.com/",
            color: "",
          },
          {
            title: "IdentifyYourHagen-Renaker",
            avatar:
              "https://www.identifyyourhagen-renaker.com/images/HRlogo.jpg",
            avatarcolor: "red",
            // link: "http://www.identifyyourhagen-renaker.com/",
            link: "https://www.identifyyourhagen-renaker.com/",
          },
          {
            title: "BreyerHorseRef",
            avatar:
              "https://www.breyerhorseref.com/sites/default/files/favicon.png",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.breyerhorseref.com/",
          },
          {
            title: "StoneHorseRef",
            avatar:
              "https://www.stonehorseref.com/sites/default/files/favicon.png",
            avatarcolor: "blue-grey lighten-4",
            // link: "http://www.stonehorseref.com/",
            link: "https://www.stonehorseref.com/",
          },
          {
            title: "Breyer Value Guide",
            avatar:
              "https://www.breyervalueguide.com/wp-content/uploads/2018/04/cropped-BVG-192x192.gif",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.breyervalueguide.com/",
          },
          {
            title: "PonyLagoon",
            avatar: "https://www.ponylagoon.com/assets/img/24x24.png",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.ponylagoon.com/",
          },
          {
            title: "Model Horse Sales Page",
            avatar: "https://modelhorsesalespages.com/favicon.ico",
            avatarcolor: "",
            link: "https://modelhorsesalespages.com/",
          },
          // {
          //   title: "CamelCamelCamel",
          //   avatar:
          //     "https://assets.camelcamelcamel.com/live-assets/favicon-b5d525d7351774e661df85aa4b9729eef15304cf328b33c71eb3ce3fe3df74a1.ico",
          //   avatarcolor: "",
          //   link: "https://camelcamelcamel.com/search?sq=breyer",
          // },
          {
            title: "PicClick",
            avatar: "https://picclick.com/favicon.ico",
            avatarcolor: "",
            link: "https://picclick.com/Collectibles/Animals/Horses-Model-Horses/",
          },
          {
            title: "WorthPoint",
            avatar:
              "https://durzpsnk5tin6.cloudfront.net/static/assets/images/favicon/favicon-32x32.png",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.worthpoint.com/",
          },
          {
            title: "Breyer Box Website",
            avatar: "https://breyerboxblog.blogspot.com/favicon.ico",
            avatarcolor: "blue-grey lighten-4",
            link: "https://breyerpackaging.webs.com/",
          },
          {
            title: "Model Horse Galery",
            avatar: "https://www.modelhorse.gallery/favicon.ico",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.modelhorse.gallery/",
          },
          {
            title: "Shoestring Stable",
            avatar: "https://s1.wp.com/i/favicon.ico",
            avatarcolor: "blue-grey lighten-4",
            link: "https://shoestringstable.wordpress.com/",
          },
          {
            title: "Red Horse Artworks",
            // link: "http://www.redhorseartworks.com/",
            link: "https://www.redhorseartworks.com/",
            // avatar: "http://www.redhorseartworks.com/favicon.ico",
            // avatarcolor: "blue-grey lighten-4",
            avatar: "",
            avatarcolor: "",
            icon: "mdi-web",
            iconcolor: "",
            color: "red",
          },
          {
            title: "Blue Mountain Stable",
            // link: "http://www.bluemountainstable.com/",
            link: "https://www.bluemountainstable.com/",
            // avatar:
            //   "http://www.bluemountainstable.com/uploads/1/1/9/8/119887565/0517-bmsbluelogo-web-round-simple.png",
            // avatarcolor: "blue-grey lighten-4",
            avatar: "",
            avatarcolor: "",
            icon: "mdi-web",
            iconcolor: "",
            color: "red",
          },
          {
            title: "Chestnut Ridge",
            avatar:
              "https://www.chestnutridge.co.uk/uploads/1/2/0/6/120645516/published/cr-logo.jpg",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.chestnutridge.co.uk/",
          },
        ],
      },
      // Outlets
      {
        title: "Outlets",
        icon: "mdi-horseshoe",
        iconcolor: "yellow darken-2",
        routes: [
          {
            title: "Aidan's Toy Trove",
            avatar:
              "https://aidanstoytrove.com/wp-content/uploads/2016/04/ATTlogo110x110.jpg",
            avatarcolor: "",
            link: "https://aidanstoytrove.com/product-category/model-horses/breyer/",
            color: "",
          },
          {
            title: "Blue Unicorn Studios",
            avatar: "https://blueunicornstudios.com/favicon.ico",
            avatarcolor: "",
            link: "https://blueunicornstudios.com/custom-horse-models",
            color: "",
          },
          {
            title: "Horse'n Around",
            avatar: "https://www.themodelhorsestore.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.themodelhorsestore.com/",
            color: "",
          },
          {
            title: "Jackson's Western",
            avatar:
              "https://cdn11.bigcommerce.com/s-p3k2n80ho/images/stencil/250x108/jw-logo_1523029755__39807.original.png",
            avatarcolor: "",
            link: "https://jacksonswestern.com/gifts/breyer-horses/",
            color: "",
          },
          {
            title: "Mercari",
            avatar: "https://www-mercari-com.akamaized.net/favicon.ico",
            avatarcolor: "",
            link: "https://www.mercari.com/us/brand/13841/",
            color: "",
          },
          {
            title: "Model Horse Place",
            avatar: "https://www.modelhorseplace.com/uploads/favicon.png",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.modelhorseplace.com/",
            color: "",
          },
          {
            title: "Model Horse Transaction Board",
            // avatar: "http://www.facebook.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.facebook.com/groups/518488248234905",
            color: "",
          },
          {
            title: "My Breyer Heart",
            avatar:
              "https://store.breyer.co/wp-content/uploads/2021/04/fav.png",
            avatarcolor: "",
            link: "https://store.breyer.co/",
            color: "",
          },
          {
            title: "PicClick",
            avatar: "https://picclick.com/favicon.ico",
            avatarcolor: "",
            link: "https://picclick.com/Collectibles/Animals/Horses-Model-Horses/",
          },
          {
            title: "PonyLagoon",
            avatar: "https://www.ponylagoon.com/assets/img/24x24.png",
            avatarcolor: "blue-grey lighten-4",
            link: "https://www.ponylagoon.com/",
          },
          {
            title: "Sarah Tregay",
            avatar: "",
            avatarcolor: "",
            link: "https://www.sarahtregay.com/",
            color: "",
          },
          {
            title: "The Model Trading Post",
            avatar: "https://www.themodeltradingpost.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.themodeltradingpost.com/",
            color: "",
          },
          {
            title: "Tractor Supply Company",
            avatar:
              "https://media.tractorsupply.com/is/image/TractorSupplyCompany/2016-tsc-favicon",
            avatarcolor: "",
            link: "https://www.tractorsupply.com/tsc/brand/Breyer",
            color: "",
          },
          {
            title: "Triple Mountain Model Horses",
            avatar:
              "https://cdn.shopify.com/s/files/1/0714/1551/files/logo_with_transparent_background_no_url_049f6907-fe22-4d87-a9dc-09c52e37cc5e_350x@2x.png",
            avatarcolor: "",
            link: "https://triplemountain.com/",
            color: "",
          },
        ],
      },
      // Groups and Associations
      {
        title: "Groups and Associations",
        icon: "mdi-account-multiple",
        iconcolor: "cyan",
        routes: [
          {
            title: "North American Model Horse Shows Association",
            avatar:
              "https://namhsa.org/content/uploads/site_images/cropped-namhsa-site-logo-150x150.png",
            avatarcolor: "",
            link: "https://namhsa.org/",
          },
          {
            title: "American Model Arabian Horse Association",
            avatar: "https://amaha-modelarabians.weebly.com/favicon.ico",
            avatarcolor: "",
            link: "https://amaha-modelarabians.weebly.com/",
          },
          {
            title: "Great Lakes Congress",
            avatar:
              "https://www.greatlakescongress.com/wp-content/uploads/2020/11/favicon-150x150.jpg",
            avatarcolor: "",
            link: "https://www.greatlakescongress.com/",
          },
          {
            title: "Rare Model Horse Sales",
            // avatar: "http://www.facebook.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.facebook.com/groups/raremodelhorses/",
          },
          {
            title: "Model Equine Photo Showers Association",
            avatar: "https://mepsa.club/favicon.ico",
            avatarcolor: "",
            link: "https://mepsa.club/",
          },
          {
            title: "Model Horse Hobby References",
            avatar: "https://groups.io/img/org.1/favicons/favicon-32x32.png",
            avatarcolor: "",
            link: "https://groups.io/g/ModelHorseHobbyReferences",
          },
          {
            title: "Breyer Horse Talk",
            avatar: "https://breyerhorsetalk.com/favicon.ico",
            avatarcolor: "blue-grey lighten-4",
            link: "https://breyerhorsetalk.com/",
          },
          {
            title: "Model Horse Madness",
            avatar: "https://modelhorsemadness.blogspot.com/favicon.ico",
            avatarcolor: "blue-grey lighten-4",
            // link: "http://modelhorsemadness.blogspot.com/",
            link: "https://modelhorsemadness.blogspot.com/",
          },
          {
            title: "Model Horse Blab",
            avatar:
              "https://modelhorseblab.com/data/assets/logo/BlabFavicon32x32.jpg",
            avatarcolor: "",
            // link: "http://www.modelhorseblab.com/",
            link: "https://www.modelhorseblab.com/",
            color: "",
          },
          {
            title: "Breyer Blog",
            avatar:
              "https://cdn.shopify.com/s/files/1/0015/8898/5892/files/Breyer_favicon_32x32_32x32.png",
            avatarcolor: "",
            link: "https://www.breyerhorses.com/blogs/news/",
            color: "",
          },
        ],
      },
      // {
      //   title: "myCollexion",
      //   // icon: "mdi-hexagon-multiple",
      //   avatar: require("@/assets/myCollexion.svg"),
      //   color: "red",
      //   routes: [
      //     // { title: "Models Card", path: "models2", icon: "mdi-home" },
      //     // { title: "Models Table", path: "models", icon: "mdi-home" },
      //     // { title: "Users", path: "users", icon: "mdi-account" },
      //     { title: "Catalog", path: "catalog", icon: "mdi-account" },
      //     { title: "In Search Of", path: "iso", icon: "mdi-magnify" },
      //     { title: "Price Guide", path: "price", icon: "mdi-currency-usd" },
      //   ],
      // },
    ],
    items2: [
      // {
      //   title: "Collexion",
      //   // icon: "mdi-hexagon-multiple",
      //   avatar: require("@/assets/collexion.svg"),
      //   color: "red",
      //   routes: [
      //     {
      //       title: "Catalog",
      //       path: "catalog",
      //       icon: "mdi-format-list-bulleted",
      //     },
      //     { title: "Reports", path: "reports", icon: "mdi-chart-bar" },
      //     { title: "Tools", path: "tools", icon: "mdi-tools" },
      //   ],
      // },
      // https://www.modelhorse.gallery/
      {
        title: "Animal Artistry",
        avatar:
          "https://www.animalartistry.co.uk/wp-content/uploads/2020/10/cropped-animalartistry_icon-32x32.png",
        avatarcolor: "",
        link: "https://www.animalartistry.co.uk/",
        color: "",
        routes: [
          {
            title: "Animal Artistry",
            avatar:
              "https://www.animalartistry.co.uk/wp-content/uploads/2020/10/cropped-animalartistry_icon-32x32.png",
            avatarcolor: "",
            link: "https://www.animalartistry.co.uk/",
            color: "",
          },
        ],
      },
      {
        title: "Beswick",
        avatar:
          "https://www.johnbeswick.co.uk/images/favicons/favicon-32x32.png",
        avatarcolor: "",
        link: "https://www.johnbeswick.co.uk/",
        color: "",
        routes: [
          {
            title: "Beswick",
            avatar:
              "https://www.johnbeswick.co.uk/images/favicons/favicon-32x32.png",
            avatarcolor: "",
            link: "https://www.johnbeswick.co.uk/",
            color: "",
          },
        ],
      },
      {
        title: "Breyer",
        avatar:
          "https://cdn.shopify.com/s/files/1/0015/8898/5892/files/Breyer_favicon_32x32_32x32.png",
        avatarcolor: "",
        link: "https://www.breyerhorses.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Breyer",
            avatar:
              "https://cdn.shopify.com/s/files/1/0015/8898/5892/files/Breyer_favicon_32x32_32x32.png",
            avatarcolor: "",
            link: "https://www.breyerhorses.com/",
            color: "",
          },
          // {
          //   title: "Catalog",
          //   path: "catalog",
          //   icon: "mdi-format-list-bulleted",
          // },
          // { title: "Reports", path: "reports", icon: "mdi-chart-bar" },
          // { title: "Tools", path: "tools", icon: "mdi-tools" },
          // {
          //   title: "Catalogs",
          //   path: "catalog",
          //   icon: "mdi-cog",
          // },
          // {
          //   title: "Accessories",
          //   path: "accessories",
          //   icon: "mdi-cog",
          // },
          // {
          //   title: "Tack",
          //   path: "tack",
          //   icon: "mdi-cog",
          // },
          // {
          //   title: "Just About Horses",
          //   path: "jah",
          //   icon: "mdi-cog",
          // },
          // {
          //   title: "Plush",
          //   path: "plush",
          //   icon: "mdi-cog",
          // },
          // {
          //   title: "Animals",
          //   path: "animals",
          //   icon: "mdi-cog",
          // },
        ],
      },
      {
        title: "CollectA",
        avatar: "https://www.collecta.biz/favicon.ico",
        avatarcolor: "",
        link: "https://www.collecta.biz/",
        color: "yellow darken-4",
        routes: [
          {
            title: "CollectA",
            avatar: "https://www.collecta.biz/favicon.ico",
            avatarcolor: "",
            link: "https://www.collecta.biz/",
            color: "",
          },
        ],
      },
      {
        title: "Copperfox Model Horses",
        avatar:
          "https://cdn.shopify.com/s/files/1/0098/3187/4639/files/Copperfoxcopper_32x32.jpg?v=1560129402",
        avatarcolor: "",
        link: "https://copperfoxhorses.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Copperfox Model Horses",
            avatar:
              "https://cdn.shopify.com/s/files/1/0098/3187/4639/files/Copperfoxcopper_32x32.jpg?v=1560129402",
            avatarcolor: "",
            link: "https://copperfoxhorses.com/",
            color: "",
          },
        ],
      },
      {
        title: "Equorium",
        link: "https://www.equorum.co.uk/",
        avatar: "",
        avatarcolor: "",
        icon: "mdi-web",
        iconcolor: "",
        color: "red",
        routes: [
          {
            title: "Equorium",
            avatar: "",
            avatarcolor: "red",
            icon: "mdi-web",
            iconcolor: "",
            link: "https://www.equorum.co.uk/",
            color: "white",
          },
        ],
      },
      {
        title: "Hagen-Renaker",
        avatar: "https://www.hagenrenaker.com/favicon.ico",
        avatarcolor: "",
        link: "https://www.hagenrenaker.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Hagen-Renaker",
            avatar: "https://www.hagenrenaker.com/favicon.ico",
            avatarcolor: "",
            link: "https://www.hagenrenaker.com/",
            color: "",
          },
        ],
      },
      {
        title: "Hartland",
        avatar: "https://www.modelhorse.gallery/H/Hartland/HRT134.gif",
        avatarcolor: "",
        link: "https://www.hartlands.com/horses.html",
        color: "yellow darken-4",
        routes: [
          {
            title: "Hartland Figurines",
            avatar: "https://www.modelhorse.gallery/H/Hartland/HRT134.gif",
            avatarcolor: "",
            link: "https://www.hartlands.com/horses.html",
            color: "",
          },
          {
            title: "Hartland Model Equestrian",
            // avatar:
            //   "http://www.hartlandhorsemen.com/Images/3-Bays_Grayscale_Modified_s.jpg",
            avatarcolor: "",
            // link: "http://www.hartlandhorsemen.com/",
            link: "https://www.hartlandhorsemen.com/",
            color: "",
          },
          {
            title: "Model Horse Galery",
            avatar: "https://www.modelhorse.gallery/favicon.ico",
            avatarcolor: "",
            // link: "http://www.modelhorse.gallery/H/Hartland/MHCs_Hartland.html",
            link: "https://www.modelhorse.gallery/H/Hartland/MHCs_Hartland.html",
            color: "",
          },
        ],
      },
      {
        title: "Julip",
        // link: "http://www.juliporiginals.co.uk/",
        link: "https://www.juliporiginals.co.uk/",
        avatar: "",
        avatarcolor: "",
        icon: "mdi-web",
        iconcolor: "",
        color: "red",
        routes: [
          {
            title: "Julip Originals",
            avatar: "",
            avatarcolor: "red",
            icon: "mdi-web",
            iconcolor: "",
            // link: "http://www.juliporiginals.co.uk/",
            link: "https://www.juliporiginals.co.uk/",
            color: "white",
          },
          {
            title: "Julip Horse of the Year",
            avatar: "",
            avatarcolor: "red",
            icon: "mdi-web",
            iconcolor: "",
            // link: "http://www.labratango.com/JulipID/HOTYmolds/horse.html",
            link: "https://www.labratango.com/JulipID/HOTYmolds/horse.html",
            color: "white",
          },
        ],
      },
      {
        title: "Lakeshore Model Horses",
        avatar: "https://www.lakeshorecollection.com/square.ico",
        avatarcolor: "grey",
        link: "https://www.lakeshorecollection.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Lakeshore Model Horses",
            avatar: "https://www.lakeshorecollection.com/square.ico",
            avatarcolor: "",
            link: "https://www.lakeshorecollection.com/",
            color: "",
          },
        ],
      },
      {
        title: "Magpie Model Horses",
        avatar: "https://www.magpiemodelhorses.uk/favicon-32x32.png",
        avatarcolor: "grey",
        link: "https://www.magpiemodelhorses.uk/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Magpie Model Horses",
            avatar: "https://www.magpiemodelhorses.uk/favicon-32x32.png",
            avatarcolor: "",
            link: "https://www.magpiemodelhorses.uk/",
            color: "",
          },
        ],
      },
      // {
      //   title: "North Light Horses",
      //   avatar: "https://northlighthorses.webs.com/IMG_0388.JPG",
      //   avatarcolor: "",
      //   link: "https://www.northlighthorses.com/",
      //   color: "yellow darken-4",
      //   routes: [
      //     {
      //       title: "North Light Horses",
      //       avatar: "https://northlighthorses.webs.com/IMG_0388.JPG",
      //       avatarcolor: "",
      //       link: "https://www.northlighthorses.com/",
      //       color: "",
      //     },
      //   ],
      // },
      {
        title: "RubberNedz",
        link: "https://www.facebook.com/RubberNedz/",
        avatar: "",
        avatarcolor: "",
        icon: "mdi-web",
        color: "red",
        routes: [
          {
            title: "RubberNedz",
            avatar: "",
            avatarcolor: "red",
            icon: "mdi-web",
            link: "https://www.facebook.com/RubberNedz/",
            color: "white",
          },
        ],
      },
      {
        title: "Schleich",
        avatar:
          // "https://www.schleich-s.com/static/version1652446630/frontend/Astound/schleich/default/Magento_Theme/favicon.ico",
          // "https://www.schleich-s.com/static/version1677138730/frontend/Astound/schleich-v2/default/images/logo.svg",
          "",
        avatarcolor: "",
        icon: "mdi-web",
        iconcolor: "",
        link: "https://www.schleich-s.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Schleich",
            avatar:
              // "https://www.schleich-s.com/static/version1652446630/frontend/Astound/schleich/default/Magento_Theme/favicon.ico",
              // "https://www.schleich-s.com/static/version1677138730/frontend/Astound/schleich-v2/default/images/logo.svg",
              "",
            avatarcolor: "",
            icon: "mdi-web",
            iconcolor: "",
            link: "https://www.schleich-s.com/",
            color: "",
          },
        ],
      },
      {
        title: "Seunta LLC",
        avatar: "https://seunta.com/favicon.ico",
        avatarcolor: "",
        link: "https://seunta.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Seunta LLC",
            avatar: "https://seunta.com/favicon.ico",
            avatarcolor: "",
            link: "https://seunta.com/",
            color: "",
          },
        ],
      },
      {
        title: "Peter Stone Horses",
        avatar:
          "https://cdn.shopify.com/s/files/1/0273/4866/4380/files/pT5b7pXac_180x180.jpg?v=1592932888",
        avatarcolor: "",
        link: "https://stonehorses.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Peter Stone Horses",
            avatar:
              "https://cdn.shopify.com/s/files/1/0273/4866/4380/files/pT5b7pXac_180x180.jpg?v=1592932888",
            avatarcolor: "",
            link: "https://stonehorses.com/",
            color: "",
          },
        ],
      },
      {
        title: "Trail of Painted Ponies",
        avatar: "https://trailofpaintedponies.com/favicon.ico",
        avatarcolor: "",
        link: "https://trailofpaintedponies.com/",
        color: "yellow darken-4",
        routes: [
          {
            title: "Trail of Painted Ponies",
            avatar: "https://trailofpaintedponies.com/favicon.ico",
            avatarcolor: "",
            link: "https://trailofpaintedponies.com/",
            color: "",
          },
        ],
      },
    ],
    items3: [
      {
        // title: "Configuration",
        // icon: "mdi-cog",
        // iconcolor: "blue darken-2",
        // icon: "mdi-hexagon-multiple",
        // iconcolor: "red",
        // title: "myCollexion",
        // avatar: require("@/assets/myGewgawLogo_512x512.png"),
        // avatar: require("/public/img/icons/favicon.svg"),
        // avatarcolor: "blue",
        title: "Configuration",
        // avatar: require("@/assets/gear-system-settings.svg"),
        // avatarcolor: "blue",
        // icon: require("@/assets/gear-system-settings.svg"),
        // iconcolor: "blue darken-2",
        // icon: require("@/icons/ConfigIcon.vue"),
        color: "green lighten-2",
        routes: [
          { path: "home", title: "Home", icon: "mdi-home" },
          { path: "about", title: "About", icon: "mdi-information" },
          { path: "profile", title: "Profile", icon: "mdi-account" },
        ],
      },
    ],
    mini: true,
  }),
  components: {
    CollexionFooter,
    ConfigIcon,
  },
  methods: {
    // navigateTo(route) {
    //   if (this.$route.name !== route) {
    //     // this.drawer = false;
    //     this.$router.push({ name: route }).catch((error) => {
    //       console.log(error);
    //     });
    //   }
    // },
    navigateTo(route) {
      // if (this.$route.name !== route) {
      if (this.$route.path !== route) {
        // this.drawer = false;
        this.$router.push({ name: route }).catch((error) => {
          console.log(error);
        });
      }
    },
    openMenu(item) {
      // this.menu = item.title;
      // this.routes = item.routes;
      this.menu = item;
      this.drawer = true;
    },
    closeMenu() {
      // this.menu = item.title;
      // this.routes = item.routes;
      // this.menu = item;
      this.drawer = false;
    },
    keyDownHandler(event) {
      if (event.code === "Escape") {
        this.drawer = false;
      }
    },
    bgHeight() {
      return this.$vuetify.breakpoint.height - 32;
    },
  },
  created() {
    // TODO initialize stores in navigation
    this.$store.commit("initializeUsers");
    // this.$store.commit("initializeCatalog");
  },
  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
};
</script>

<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
/* a {
  color: white;
  text-decoration: none;
} */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* .no-scroll::-webkit-scrollbar {
  display: none; // Chrome, Safari and Opera
} */
::v-deep ::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
  width: 0;
  background: transparent;
}
/* TODO navigation.vue overflow-y: hidden somewhere */
/* html {
  overflow-y: hidden;
}
.v-application {
  overflow-y: hidden;
}
.v-navigation-drawer__content {
  overflow-y: hidden;
} */
</style>
