// @/store/index.js

import Vue from "vue";
import Vuex from "vuex";

import UserModule from "./modules/user.js";
import PackageModule from "./modules/package.js";
// import CatalogModule from "./modules/catalog.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // modules will go here
    user: UserModule,
    package: PackageModule,
    // catalog: CatalogModule,
  },
});
