<template>
  <v-footer app bottom padless class="px-4 py-0 my-0" dark height="32">
    <v-row align="center">
      <span>&copy;&nbsp;{{ new Date().getFullYear() }}&nbsp;</span>
      <!-- <span class="green--text"> -->
      <span style="color: #85bb65">
        {{ $store.getters.appAuthor }}
      </span>

      <v-spacer></v-spacer>

      <!-- Social Media -->
      <v-btn
        v-for="media in medias"
        :key="media.icon"
        icon
        :href="media.link"
        target="_blank"
      >
        <v-icon :color="media.color">
          {{ media.icon }} {{ media.title }}
        </v-icon>
      </v-btn>

      <!-- <v-spacer></v-spacer> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "CollexionFooter",
  data: () => {
    return {
      medias: [
        {
          title: "myCollexion",
          link: "https://mygewgaw.business.site/",
          avatar: require("@/assets/myGewgawLogo_512x512.png"),
          avatarcolor: "white",
          // color: "green lighten-2",
          color: "#85bb65",
          icon: "mdi-web",
        },
        {
          title: "facebook",
          link: "https://facebook.com",
          color: "#1877F2",
          icon: "mdi-facebook",
        },
        {
          title: "twitter",
          link: "https://twitter.com",
          color: "#1DA1F2",
          icon: "mdi-twitter",
        },
        {
          title: "instagram",
          link: "https://instagram.com",
          color: "#E4405F",
          icon: "mdi-instagram",
        },
        {
          title: "youtube",
          link: "https://youtube.com",
          color: "#CD201F",
          icon: "mdi-youtube",
        },
      ],
    };
  },
};
</script>
