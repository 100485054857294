// import user from "@/api/users";
export default {
  // namespaced: true,
  state: {
    // = data
    userList: [
      // {
      //   id: 1,
      //   name: "Carl Knox",
      //   username: "userModule userList",
      //   email: "carl.knox@wellsfargo.com",
      //   phoneNumber: "612-677-9185",
      // },
    ],
  },

  getters: {
    // = computed properties
    // fullName: function (state) {
    // return `${state.firstName} ${state.lastName}`
    //   return state.userList;
    // },
    getUsers: (state) => state.userList,
    // getUsers1: (state) => {
    //   console.log("getUsers1");
    //   return state.userList;
    // },
  },

  mutations: {
    setUserList(state, payload) {
      state.userList = payload;
    },

    initializeUsers(state) {
      console.log("userModule initializeUsers");
      // TODO get data from api, database, file, store-persist
      const users = [
        // {
        //   id: 1,
        //   name: "Carl Knox",
        //   username: "userModule initializeUsers",
        //   email: "carl.knox@wellsfargo.com",
        //   phoneNumber: "612-677-9185",
        // },
        // {
        //   id: 2,
        //   name: "Sean Ewing",
        //   username: "userModule initializeUsers",
        //   email: "sean.ewing@wellsfargo.com",
        //   phoneNumber: "612-677-9185",
        // },
        // {
        //   id: 3,
        //   name: "Bryan Lakey",
        //   username: "userModule initializeUsers",
        //   email: "bryan.lakey@wellsfargo.com",
        //   phoneNumber: "612-677-9185",
        // },
      ];
      state.userList = users;
      // return Promise.resolve(state.userList);
      return state.userList;
    },
  },

  actions: {
    // = methods
    addToCart(contex, payload) {
      contex.commit("addToCart", payload);
    },

    removeCart(contex, payload) {
      contex.commit("removeCart", payload);
    },

    setUserList(contex, payload) {
      contex.commit("setUserList", payload);
    },
  },

  methods: {},

  // TODO created() doesn't seem to get called
  created() {
    // this.$store.dispatch("getCartItems");
    // this.user = [ // initialize with dummy data
    //   {
    //     firstName: 'John',
    //     lastName: 'Doe'
    //   },
    // ];
    // user.getUsers((users) => {
    //   this.userList = users;
    // });
  },
};
